.review-item-container {
	.review-item-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.avatar-name-container {
			display: flex;
			align-items: center;

			.small-avatar {
				width: 20px;
				height: 20px;
				margin-right: 5px;
				border-radius: 2px;
			}
		}
	}

	.review-item-body {
		padding-left: 25px;

		.review-content {
			font-size: 13px;
		}

		.user-date-container {
			font-size: 10px;
		}
	}
}
