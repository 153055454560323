.chats-page {
  .chat-item-container {
    font-size: 12px;

    ion-avatar {
      background-color: var(--ion-color-greyish-white);
      .no-image {
        color: white;
        font-size: 10px;
      }
    }

    .shop-name-date-container {
      display: flex;
      justify-content: space-between;
    }

    .msg-badge-container {
      display: flex;
      justify-content: space-between;
    }
  }
}
