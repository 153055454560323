.shop-products-tab {
  .toolbar {
    display: flex;
    align-items: center;

    * {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ion-button {
      width: max-content;

      display: flex;
      justify-content: center;
      align-items: center;

      * {
        padding: 0;
        margin: 0;
        aspect-ratio: 1;
        width: min-content;
        margin-right: 0.3rem;
      }

      &:last-child * {
        margin-left: auto;
      }

      &:first-of-type {
        margin-right: auto;
      }

      &:nth-of-type(2) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .tab-searchbar {
    height: 2rem;
    padding-inline-end: 0;

    .searchbar-search-icon {
      position: absolute;
      top: 0;
      height: 100%;
      width: 17px;
    }

    input {
      height: 2rem;
      font-size: 13px;
    }
  }
}

.p-10{
 padding: 10px;
}