.category-box-container {
	position: relative;
	width: 100%;
	height: 50px;	// default only
	border-radius: 8px;

	.category-title {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 50%;
		text-align: center;
		color: white;
		font-weight: 500;
		border-radius: 10px;
		font-size: 13px;
	}

	.category-title:after {
		content: '';
		width: 1px;
		height: 100%;
		vertical-align: middle;
		display: inline-block;
	}

	// perform actual vertical middle
	.category-title span {
		display: inline-block;
		vertical-align: middle;
	}
}

.category-op {
	opacity: 0.8 !important;
	background-size: contain;
}

