.image-upload-container {
  width: fit-content;

  &:last-of-type {
    margin-bottom: 1rem;
  }

  .image-container {
    position: relative;
    border-radius: 10px;
    width: 65px;
    height: 65px;
    @media (min-width: 768px) {
      width: 125px;
      height: 125px;
    }
    background-color: lightgray;

    .upload-button {
      position: absolute;
      bottom: -6px;
      right: -12px;

      ion-icon {
        // margin-right: 5px;
        // width: 15px;
        // height: 15px;
        color: #adadad;
      }

      // .icon-button::part(native) {
      //   padding: 19px 6px 18px 10px;
      // }
    }

    .upload-button::part(native) {
      // padding: 19px 6px 18px 10px;

      // according to figma
      aspect-ratio: 1;
      border-radius: 100%;
      background-color: white;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    }
  }

  .add-image-btn {
    width: 65px;
    height: 65px;
    @media (min-width: 768px) {
      width: 125px;
      height: 125px;
    }
    border: 1px solid var(--ion-color-grey-lightest);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
