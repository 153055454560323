.fav-shop-card-container {
  position: relative;
  //   display: flex;
  //   width: 100%;

  ion-card {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  }

  .fav-shop-images {
    display: flex;
    justify-content: flex-start;
  }

  .shop-product-image {
    margin: 0.3em 1em 1em 1em;
    width: 270px;
    height: 270px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    @media (max-width: 768px) {
      width: 150px;
      height: 150px;
    }
    @media (max-width: 576px) {
      width: 74px;
      height: 74px;
      font-size: 8px;
    }
  }

  .shop-circle {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    z-index: 1;
  }

  .slide {
    position: relative;
    z-index: 2;
    animation: slideToRight 0.5s forwards;
  }
}

.slide-back {
  animation: slideToLeft 0.5s forwards;
}
