.bottom-bar {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  animation: slideFromBottom 0.5s forwards;
}

.content {
  p {
    margin-left: 1.25rem;
  }
}

.round {
  margin-left: 100%;
  transform: translateX(-100%);
  aspect-ratio: 1;
  * {
    padding: 0;
  }
}

.slide {
  animation: slideToHide 1s forwards;
}

// .product-card-grid {
//   margin-bottom: 1rem;
// }
