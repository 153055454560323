.p-20{
  padding: 30px;
}

.text-smaller{
font-size: 14px;
}
//modal stuff
ion-modal {
  --height: auto;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding:13px;
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --color: black;
}

.modal-wrapper{
  border-radius: 30px !important;
}

ion-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
}

ion-modal .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}


ion-modal h3{
  text-align: center;
  color: #6f6f6f;
}

ion-modal hr{
  background: #e4e4e4;
}

ion-modal p{
  text-align: left;
}

ion-modal ion-button{
  width: 80%;
  margin: 0 auto
}