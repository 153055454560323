.homepage {
	.view-all::part(native) {
		font-size: 13px;
	}

	.view-all {
		--background-hover: transparent
	}

	.category {
		margin-left: 1.5rem;
	}

	ion-title {
		text-align: left;
		padding: 1.5rem;
		font-size: 18px;
	}
}

.jst{
	justify-content: start;
}