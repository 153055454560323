.carousel-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	-ms-overflow-style: none; /* hide scrollbar in IE and Edge */
	scrollbar-width: none; /* hide scrollbar in Firefox */
	.carousel-wrapper {
		-ms-overflow-style: none; /* hide scrollbar in IE and Edge */
		scrollbar-width: none; /* hide scrollbar in Firefox */
		-webkit-overflow-scrolling: touch;
		display: flex;
		width: 100%;
		position: relative;
	}

	.carousel-content-wrapper {
		// overflow: hidden;
		-ms-overflow-style: none; /* hide scrollbar in IE and Edge */
		scrollbar-width: none; /* hide scrollbar in Firefox */
		-webkit-overflow-scrolling: touch;
		width: 100%;
		height: 100%;
	}

	.carousel-content {
		-ms-overflow-style: none; /* hide scrollbar in IE and Edge */
		scrollbar-width: none; /* hide scrollbar in Firefox */
		-webkit-overflow-scrolling: touch;
		display: flex;
		transition: all 250ms linear;
		width: 100%;
		height: 100%;
	}

	::-webkit-scrollbar {
		height: 0px;
		display: none;
		background: transparent; /* make scrollbar transparent */
	}

	/* hide scrollbar in webkit browser */
	.carousel-content::-webkit-scrollbar,
	.carousel-content::-webkit-scrollbar {
		display: none;
	}

	.carousel-content > * {
		width: 100%;
		flex-shrink: 0;
		flex-grow: 1;
	}

	.carousel-content.show-2 > * {
		width: 50%;
	}

	.carousel-content.show-3 > * {
		width: calc(100% / 3);
	}

	.carousel-content.show-4 > * {
		width: calc(100% / 4);
	}

	.left-arrow,
	.right-arrow {
		position: absolute;
		z-index: 1;
		top: 50%;
		transform: scale(0.8) translateY(-70%);
		width: 48px;
		height: 48px;
		display: none;
	}

	.left-arrow {
		left: 24px;
	}

	.right-arrow {
		right: 24px;
	}

	@media (hover: none) and (pointer: coarse) {
		.left-arrow,
		.right-arrow {
			display: none;
		}
	}

	.indicator {
		margin: 0.1em;
		width: 7px;
		height: 7px;
		border-radius: 2px;
	}
}

.carousel-container:hover {
	.left-arrow,
	.right-arrow {
		display: block;
	}
}
