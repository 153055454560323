.shop-profile-page {
  .long-profile-button {
    margin-left: 6rem;
    width: 200px;
  }

  .profile-container {
    margin: 0 1rem;
    text-align: justify;
    padding-bottom: 1rem;
    span,
    p {
      font-size: 12px;
    }

    .profile-buttons {
      display: flex;
      .icon-button::part(native) {
        border-radius: 8px;
      }

      .contact-button {
        transform: scale(0.8, 0.8);
        height: 10px;
        // padding-bottom: 10px;
      }
    }
  }

  .read-more {
    color: var(--ion-color-primary);
    cursor: pointer;
  }

  ion-title {
    animation: fade 0.3s forwards;
  }
}

.title-show {
  animation: fade 1s forwards;
}

.title-remove {
  animation: fade 1s reverse forwards;
}
