.terms-of-service {
	text-decoration: underline;
	
}

.text-color-grey-lightest{
	font-size: 12px;
}


.button-padding{
	padding-top:10px;
}

item-inner{

	padding-inline-end: none;
	padding-inline-start: none;
}

.input {
border-radius: 0px;
}

.item-native{
border-radius: 0px !important;
}