.fav-page {
	ion-title {
		text-align: left;
		padding: 1.5rem;
		font-size: 18px;
	}

	.toolbar {
		font-size: 15px;
	}
}
