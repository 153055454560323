.searchbar {
  .searchbar-input {
    background: white;
    // outline: 1px solid var(--ion-color-grey-lighter);
    border-radius: 10px;
    // box-shadow: none;
  }

  .searchbar-input:focus-within {
    border-color: var(--ion-color-primary);
    animation: glow 800ms forwards;
  }
}
.searchbarios {
  .searchbar-input {
    background: white;
    // outline: 1px solid var(--ion-color-grey-lighter);
    border-radius: 10px;
    // box-shadow: none;
  }

  .searchbar-input:focus-within {
    // border-color: var(--ion-color-primary);
    // animation: glow 800ms forwards;
  }
}
