.product-card-container {
  max-width: min-content;
  margin: 0.5rem;

  @media (max-width: 320px) {
    margin: 1rem;
  }

  .product-image {
    width: 270px;
    height: 270px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      width: 150px;
      height: 150px;
    }
    @media (max-width: 320px) {
      width: 250px;
      height: 250px;
    }
  }

  .margin-vertical {
    margin: 0.3rem 0;
  }

  a {
    color: black;
  }
}
