.section-tabs {
  .section-tab-buttons {
    margin-bottom: 0.5rem;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    .section-tab-button {
      margin-top: -5px;
      font-size: 15px;
      background: white;
      border: none;
      outline: none;
      padding: 10px 20px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.4);
      transition: all ease-in-out 0.2s;
      border-bottom: 2px solid transparent;
      display: inline-block;
      width: 50%;
      border-bottom: 3px solid var(--ion-color-secondary);
      font-weight: 500;
    }

    .active {
      border-bottom: 3px solid var(--ion-color-primary);
      color: var(--ion-color-primary);
    }
  }
}
