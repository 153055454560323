.add-product-page {
  ion-title {
    padding: 0;
  }

  ion-grid {
    height: 100%;
  }

  ion-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  ion-avatar {
    margin: 1rem auto;
  }

  ion-content {
    --padding-start: 1rem;
    --padding-end: 1rem;

    form {
      width: 100%;
    }
  }

  ion-select {
    width: 100%;
  }

  .order-type {
    margin-bottom: 0.3em;
  }
}
