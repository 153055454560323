.avatar-upload-container {
  position: relative;
  margin: auto;

  ion-avatar {
    width: 94px;
    height: 94px;
  }

  .empty-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--ion-color-greyish-white);
    border-radius: 100%;
    font-size: 13px;
    color: var(--ion-color-grey-lightest);
    background-color: var(--ion-color-greyish-white);
  }

  .upload-button {
    position: absolute;
    bottom: 0;
    ion-icon {
      margin-right: 5px;
    }
  }

  .upload-button::part(native) {
    padding: 19px 6px 18px 10px;
    border-radius: 100%;
  }
}
