@keyframes slideFromBottom {
  from {
    transform: translateY(1rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideToHide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes slideToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(4rem);
  }
}

@keyframes slideToLeft {
  from {
    transform: translateX(4rem);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideFromBottomFab {
  from {
    bottom: 0;
  }
  to {
    bottom: 30px;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 3px var(--ion-color-primary-shade);
  }
  100% {
    box-shadow: 0 0 7px var(--ion-color-primary);
  }
}
