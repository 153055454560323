.small-product-card-container {
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  max-width: min-content;

  .product-image {
    position: relative;
    width: 270px;
    height: 270px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    @media (max-width: 768px) {
      width: 150px;
      height: 150px;
    }
    @media (max-width: 576px) {
      width: 98px;
      height: 98px;
      font-size: 12px;
    }
    @media (max-width: 320px) {
      width: 130px;
      height: 130px;
      font-size: 12px;
    }
  }

  .margin-vertical {
    margin: 0.3em 0;
  }

  .product-name {
    font-size: 16px;
    @media (max-width: 576px) {
      font-size: 14px;
    }
    width: 270px;
    @media (max-width: 768px) {
      width: 150px;
    }
    @media (max-width: 576px) {
      width: 98px;
    }
  }

  .price {
    font-size: 15px;
    @media (max-width: 576px) {
      font-size: 13px;
    }
  }

  .circle {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .layer {
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      background-color: hsla(0, 0%, 100%, 0.5);
      animation: fade 0.03s forwards;
    }
  }
}
