.edit-about-us-page {
  ion-title {
    padding: 0;
  }

  ion-grid {
    height: 100%;
  }

  ion-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  ion-content {
    --padding-start: 1rem;
    --padding-end: 1rem;

    form {
      width: 100%;
      .subtitle-medium-12 {
        margin-top: 1.3rem;
      }
    }
  }

  .input-container {
    margin-top: 0.5rem !important;
  }
}
