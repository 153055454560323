.shop-about-tab {
	.toolbar {
		display: flex;
		justify-content: flex-end;
	}

	.toolbar-button {
		padding-right: 0.3em;
	}

	.content {
		font-size: 12px;
		padding-bottom: 1em;
	}
}
