.searching-view {
	.history-item {
		font-size: 16px !important;
	}

	.small-avatar {
		width: 19px;
		height: 19px;
	}

	.history-icon {
		font-size: 19px;
	}

	.popular-items-container {
		padding: 10px;
	}
}

.searching-view::part(scroll){
	overflow-y: clip;
}