.detailed-review-item-container {
	.detailed-review-item-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 0.5em;

		.avatar-name-container {
			font-size: 15px;
			--padding-start: 10px;
			--min-height: 24px;

			.small-avatar {
				width: 17px;
				height: 17px;
				margin-right: 7px;
			}
		}

		.date {
			font-size: 10px;
		}
	}

	.review-item-body {
		padding-left: 38px;

		.review-content {
			font-size: 13px;
		}

		.user-date-container {
			font-size: 10px;
		}
	}
}
