/** Global classes**/

/** Text **/
.text-color-grey {
  color: var(--ion-color-grey) !important;
}

.text-color-grey-title {
  color: var(--ion-color-grey-title) !important;
}

.text-color-grey-lighter {
  color: var(--ion-color-grey-lighter) !important;
}

.text-color-grey-lightest {
  color: var(--ion-color-grey-lightest) !important;
}

.text-bold {
  font-weight: 500 !important;
}

/** Ionic UI component **/
ion-button {
  --border-width: 1px;
}

.sc-ion-buttons-md-s :hover {
  --background-hover: transparent;
}

ion-checkbox {
  vertical-align: middle;
  margin: 0.5rem;
}

ion-icon {
  vertical-align: middle;
}

ion-note {
  vertical-align: middle;
}

ion-content {
  --overflow: auto;
}

ion-radio {
  margin: 0.5rem;
}

ion-textarea {
  .textarea-wrapper {
    .native-textarea {
      min-height: 40px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

ion-item.sc-ion-textarea-md-h:not(.item-label),
ion-item:not(.item-label) .sc-ion-textarea-md-h {
  margin-top: 10px;
  margin-bottom: 10px;
}

/** General UI component **/
.radio-label {
  margin-right: 1rem;
}

.input-container {
  margin: 0.5rem 0 1rem;
  ion-item {
    --min-height: 40px;
    border-radius: 8px;
  }

.item-inner, .item-native{
  border-radius: 5px;
}

}

.textarea-container {
  margin: 0.5rem 0 1rem;
  ion-item {
    --min-height: 60px;
  }
}

.input {
  border: 1px solid var(--ion-color-grey-lightest);
  border-radius: 5px;
  --padding-start: 10px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
         border-radius: 5px;
  //overflow:hidden;
}

.input:focus-within {
  animation: glow 800ms forwards;
  border-color: var(--ion-color-primary);
}

.bordered-ion-item {
  border: 1px solid var(--ion-color-grey-lightest);
  border-radius: 5px;
  --padding-start: 10px;
  --background-activated: none;
  --min-height: 40px;
}

.bordered-ion-item:focus-within {
  animation: glow 300ms forwards;
  border-color: var(--ion-color-primary);
}

a {
  text-decoration: none;
}

.toolbar-ion-title {
  text-align: left;
  padding: 1.5rem;
  font-size: 18px;
  color: var(--ion-color-grey-title);
  font-weight: 500;
}

.toolbar-button {
  padding-right: 0.3em;
}

.default-icon-button {
  height: 36px !important;
}

.subtitle-heavy-14 {
  font-size: 14px;
  font-weight: bold;
}

.subtitle-medium-14 {
  font-size: 14px;
  font-weight: 500;
}

.subtitle-medium-12 {
  font-size: 12px;
  font-weight: 500;
}

.form-description-14 {
  font-size: 14px;
}

.word-count {
  font-size: 12px;
}

.spinner-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    width: 40px;
    height: 40px;
  }
}

/** Helpers **/
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.center-container {
  position: relative;
}

.center-element {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/** Auth pages **/
.auth-page {
  // margin: 1.5rem;

  ion-grid {
    height: 100%;
    overflow: auto;
  }

  ion-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  ion-avatar {
    margin: 1rem auto;
  }
}

.auth-heading {
  font-weight: bold;
  font-size: 22px;
  color: var(--ion-color-grey);
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.auth-button {
  height: 3.2rem;
}

.auth-slogan {
  margin: 0.5rem;
  padding-bottom: 2rem;
  color: var(--ion-color-grey-lighter);
}

/** shop **/
.shop-name-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;

  .small-avatar-container {
    width: 20px;
    margin-right: 5px;
  }

  .small-avatar {
    width: 20px;
    height: 20px;
  }

  .shop-name {
    margin-right: 5px;
    font-size: 15px;
  }

  .checkmark-container {
    width: 15px;
    // margin-right: 5px;
  }

  .checkmark {
    width: 15px;
    height: 15px;
  }
}

/** Settings pages **/
.settings-subtitle {
  font-size: 13px;
  margin-bottom: 0px;
}

.settings-avatar {
  width: 61px;
  height: 61px;
}

.settings-page {
  ion-item::part(native) {
    padding-left: 1rem;
  }
}

/** Profile **/
.profile-avatar {
  width: 60px;
  height: 60px;
}

/** Product **/
.product-card-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, max-content));
  justify-content: center;
  padding: initial;
  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(160px, max-content));
  }
  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
  }
}

.small-product-card-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, max-content));
  justify-content: center;
  padding: initial;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(160px, max-content));
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(108px, max-content));
  }
  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(140px, max-content));
  }
}

/** Review **/
.upload-image-grid {
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fit, minmax(75px, max-content));
  // justify-content: space-between;
  padding: initial;
}


.position-top-center {
  top: 50px !important;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}


ion-app{

  margin: 0 !important;
}

.ant-message-notice{
overflow-wrap: anywhere;
inline-size: 100%;
width: calc(130% - 0px) !important;
float: left;
display: flex;
}

.position-top-center{

left:41% !important;
}

.modal-wrapper{
  border-radius: 30px !important;
}


ion-modal.auto-height {
  &.bottom {
      align-items: flex-end;
  }


  --height: auto;

  .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
          max-height: 80vh;
          overflow: auto;
          padding: 10px;
      }
  }
}
