.add-review-page {
  ion-content {
    --padding-start: 1rem;
    --padding-end: 1rem;
  }

  .product-container {
    display: flex;
    align-items: center;

    .product-name {
      font-size: 13px;
      margin-bottom: 0.5rem;
    }

    .shop-name {
      font-size: 12px;
    }

    .product-image {
      width: 270px;
      height: 270px;
      font-size: 14px;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        width: 150px;
        height: 150px;
      }
      @media (max-width: 576px) {
        width: 60px;
        height: 60px;
        font-size: 12px;
      }
    }
  }

  .rating-star {
    width: 24px !important;
  }
}
