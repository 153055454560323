.edit-profile-page {
	ion-title {
		padding: 0;
	}

	ion-grid {
		height: 100%;
	}

	ion-row {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	ion-avatar {
		margin: 1rem auto;
	}

	ion-content {
		--padding-start: 1rem;
		--padding-end: 1rem;

		form {
			width: 100%;
			.subtitle-medium-12 {
				margin-top: 1.3rem;
			}
		}
	}

	ion-select {
		width: 100%;
	}

	.input-container {
		margin-top: 0.5rem !important;
	}

	.gender-options {
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	ion-checkbox {
		--size: 21px;
	}

	.contact-header {
		margin-top: 0.9rem;
		.subtitle-medium-12 {
			margin: 10px 0;
		}

		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.description {
		font-size: 14px;
	}

	.contact-container {
		padding: 0;

		.select-menu-container {
			padding-left: 0;
		}

		.contact-input {
			margin: 0 !important;
		}

		.remove-btn-container {
			padding-right: 0;

			.remove-btn-innercontainer {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.icon-button::part(native) {
				padding: 0 !important;
			}
		}
	}

	.special-subtitle {
		margin-top: 0.5rem !important;
	}
}
