.become-shop-owner-page {
	ion-title {
		padding: 0;
	}

	ion-grid {
		height: 100%;
	}

	ion-row {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	ion-avatar {
		margin: 1rem auto;
	}

	ion-content {
		--padding-start: 1rem;
		--padding-end: 1rem;

		form {
			width: 100%;
			.subtitle-medium-12 {
				margin-top: 1.3rem;
			}
		}
	}

	ion-select {
		width: 100%;
	}

	.terms-cond-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		.terms-cond-input {
			flex-grow: 1;
			display: flex;

			ion-item {
				flex-grow: 1;
			}

			ion-textarea {
				.native-textarea {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}

		.agreement-container {
			font-size: 14px;

			.terms-of-conditions {
				text-decoration: underline;
			}
		}
	}

	.contact-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.description {
		font-size: 14px;
	}

	.contact-container {
		padding-left: 0;
		padding-right: 0;

		.select-menu-container {
			padding-left: 0;
		}

		.contact-input {
			margin: 0 !important;
		}

		.remove-btn-container {
			padding-right: 0;

			.remove-btn-innercontainer {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.icon-button::part(native) {
				padding: 0 !important;
			}
		}
	}
}
