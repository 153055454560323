.categories-page {
  ion-title {
    text-align: left;
    padding-left: 2.5rem;
    font-size: 18px;
  }

  .category {
    height: 82px;
  }
}
