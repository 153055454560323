.error-note {
	text-align: left;
	padding: 0;
	margin: 0;
	color: var(--ion-color-danger);
	font-size: 14px;
	overflow: visible;

	.error-message {
		margin-left: 3px;
		vertical-align: middle;
	}
}
