.product-page {
  ion-toolbar {
    --background: transparent;
  }

  ion-content {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
  }

  .product-image {
    height: 340px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .product-details {
    padding: 0 1rem;

    .heart-icon {
      margin-bottom: 3px;
    }

    .fav-number {
      padding-left: 0.3em;
      color: var(--ion-color-medium);
    }

    .price {
      margin: 0.5em 0;
    }

    .reviews-link {
      font-size: 13px;
      padding-left: 0.5em;
    }
  }

  .shop-container {
    .shop-avatar {
      width: 27px;
      height: 27px;
    }

    .shop-name {
      font-size: 15px;
    }

    .shop-followers {
      font-size: 10px;
    }

    ion-item {
      --inner-padding-end: 0;
    }
  }

  .footer {
    background-color: white;

    // .like-button {
    // 	width: 42px !important;
    // 	height: 42px !important;
    // }

    .contact-button {
      box-shadow: 2px 5px 23px 0px #c6951840;
    }

    .icon-button::part(native) {
      border-radius: 10px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    }
  }
}

.yellow-color{
  color: var(--ion-color-primary) !important;
}
