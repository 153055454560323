.bottom-sheet {
  .modal-wrapper {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    // TO-FIX
    // min-height: 75%;
    // height: clamp(5rem, 75vh, 100%);
    // height: 75vh;
    bottom: 0;
    height: 480px;
    position: absolute;
    display: block;
  }

  //   .modal-shadow {
  //     height: 50%;
  //     top: 50%;
  //     // height: 1rem;
  //     position: absolute;
  //     display: block;
  //   }

  .bottom-sheet-content::part(scroll) {
    overflow: hidden;
    height: fit-content;
    // margin-top: auto;
  }

  ion-title {
    padding-inline-start: 16px;
    text-align: left;
    font-weight: 500;
  }

  .range-slider {
    padding-top: 0;
  }

  .range-input {
    font-size: 14px;
    width: 5rem;
    border-bottom: 1px solid var(--ion-color-secondary);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .price-range {
    font-size: 14px;
    display: flex;

    ion-item {
      height: 3rem;
    }
  }
}
