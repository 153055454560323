.shop-reviews-tab {
	.avg {
		padding-top: 1em;
	}
  
	.reviews-subtitle {
		margin-top: 2rem;
	}

	.rating-container {
		display: flex;
		align-items: flex-end;

		.rating-value {
			font-size: 30px;
		}

		.rating-total {
			font-size: 12px;
			padding: 0.2rem 0.5rem;
		}
	}

	.no-reviews {
		font-size: 13px;
	}
}
