.verify-page {
  .digit-input {
    width: 50px;
  }
  form > .digit-input {
    display: inline-block;
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  .verify-form .input {
    height: 2rem;
    text-align: center;
    font-size: 10px;
  }
  .time {
    font-weight: bold;
  }
}
