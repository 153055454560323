.chatroom-page {
  .header {
    padding: 0.5em;
    --border-width: 1px;
    --border-color: var(
      --ion-toolbar-border-color,
      var(--ion-border-color, var(--ion-color-step-150, #c1c4cd))
    );
    ion-avatar {
      width: 35px;
      height: 35px;
      margin: 0 1rem;
    }
    .shop-name {
      font-size: 16px;
    }
    .online-time {
      font-size: 12px;
      color: green;
    }
  }

  .message-window {
    flex-grow: 2;
    overflow: auto;
    overflow-x: hidden;
    padding: 10px;

    .message {
      p {
        display: inline-block;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        // margin: 0.3rem;
        text-align: left;
        max-width: 70%;
        color: var(--ion-color-grey-title);
      }
    }

    .message-time {
      margin-top: 0;
      color: var(--ion-color-grey-lightest);
      font-size: 12px;
      text-align: center;
    }

    .sent-message {
      @extend .message;
      text-align: right;
      p {
        background: var(--ion-color-primary);
      }
    }

    .received-message {
      @extend .message;
      text-align: left;
      p {
        background: var(--ion-color-greyish-white);
      }
    }

    .seen {
      margin-right: 0.3rem;
    }
  }

  ion-footer {
    background-color: var(--ion-color-white);
    color: var(--ion-color-grey-title);

    ion-textarea {
      margin-left: 8px;
    }

    ion-grid {
      padding-top: 0;
    }
  }

  .product-item {
    @extend .product-details;
    background-color: rgba(229, 229, 229, 0.5);
    height: 55px;
    color: var(--ion-color-grey-title);
  }

  .product-details {
    .product-name {
      font-size: 15px;
      padding-bottom: 5px;
    }

    .product-price {
      font-size: 12px;
    }

    .product-image-container {
      padding: 8px;
      .product-image {
        width: 40px;
        height: 40px;
      }
    }
  }

  .clicked-product-item-container {
    // padding-right: 0;

    .clicked-product-item {
      @extend .product-details;
      // background-color: rgba(229, 229, 229, 0.5);
      background-color: #f2f2f2;
      border-radius: 10px;
      padding: 0 !important;

      .left-border {
        width: 5px;
        height: 55px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: var(--ion-color-primary);
      }
    }
  }

  .special-message {
    background-color: var(--ion-color-greyish-white);
    border-radius: 10px;
    width: 90%;
  }
}

.chatroom-drawer {
  --width: 90%;

  .mentioned-product-item {
    font-size: 12px;

    .product-image-container {
      padding: 0 8px 8px 0;
      .product-image {
        width: 60px;
        height: 60px;
      }
    }
  }
}
