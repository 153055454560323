.tabpage {
  .fab-vertical-bottom {
    bottom: 30px;
    animation: slideFromBottomFab 0.5s forwards;
  }

  ion-fab {
    margin: 0;
    transform: translateX(-50%);
  }

  ion-fab-button {
    width: 46px;
    height: 46px;
  }
}

ion-tab-bar {
  animation: slideFromBottom 0.5s forwards;
}
